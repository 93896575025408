import React from 'react'
import { Container, Image, Row } from 'react-bootstrap'

const MobileQuickCapture = () => {
    return (
        <Container>
            <Row style={{width: '100%', justifyContent: 'center', marginTop: '3%'}}>
                <Image
                    style={{
                        width: '600px',
                    }}
                    src={require('../assets/mobilequickcapture-logo.png')}
                />
            </Row>
            <Row style={{ marginTop: 15 }}>
            <p>Integrate camera functionality into your iOS Unreal Engine projects with ease. Capture high-resolution photos and use Blueprint methods to save and manage these images efficiently. Plus, handle your photos and files with a comprehensive set of Blueprint utilities.</p>
            <div>
                <h5>Key Features</h5>
                <ul>
                    <li><b>Easy Camera Integration</b>: Capture high-resolution photos directly within your app.</li>
                    <li><b>Automatic Permissions Handling</b>: Manages permissions automatically.</li>
                    <li><b>Blueprint Utilities</b>: Save images to the camera roll, load them as textures, and manage files with nodes for moving, copying, renaming, and checking file sizes.</li>
                    <li><b>Optimized Performance:</b> Lightweight and optimized to keep your app running smoothly.</li>
                    <li><b>Demo Scene:</b> Included to get you up and running quickly.</li>
                </ul>
            </div>
                <p>
                    <a href='https://www.unrealengine.com/marketplace/en-US/product/mobile-quick-capture'>Mobile Quick Capture - Unreal Engine Marketplace</a>
                </p>
            </Row>
            <Row style={{width: '100%', marginTop: '1%', flexDirection: 'row',  display: 'inline-block'}}>
                <h3>Related Files</h3>
                <a href='https://www.herocode.us/mobilequickcapture/mobilequickcapture.pdf'>Mobile Quick Capture Documentation</a>
            </Row>
        </Container>
    )
}

export default MobileQuickCapture