import React from 'react'
import { Container, Image, Row } from 'react-bootstrap'

const MobileReviewAssist = () => {
    return (
        <Container>
            <Row style={{width: '100%', marginTop: '3%'}}>
                <h1>Mobile Review Assist</h1>
                <Image
                    style={{
                        width: 600,
                        marginTop: 15,
                        marginBottom: 25,
                    }}
                    src={require('../assets/mobilereviewassist-logo.png')}
                />
            </Row>
            <Row>
                <div>
                    <p>Features:</p>
                    <ul>
                        <li>Simple integration with the Request App Review blueprint node.</li>
                        <br/>
                        <li>Compatible with both Android and iOS.</li>
                        <br/>
                        <li>Quick setup—no need for platform-specific adjustments.</li>
                    </ul>
                </div>
                <p>Quick Reviews, Instant Impact!</p>
                <p>
                    <a href='https://www.unrealengine.com/marketplace/en-US/product/mobile-review-assist'>Mobile Review Assist - Unreal Engine Marketplace</a>
                </p>
            </Row>
            <Row style={{width: '100%', marginTop: '1%', flexDirection: 'row',  display: 'inline-block'}}>
                <h3>Related Files</h3>
                <a href='https://www.herocode.us/mobilereviewassist/mobilereviewassist.pdf'>Mobile Review Assist Documentation</a>
            </Row>
        </Container>
    )
}

export default MobileReviewAssist